<template src="./ViewAllResearchGroups.html"></template>

<script>
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import ResearchGroupsVerticalCard from "../../components/ResearchGroupsVerticalCard/ResearchGroupsVerticalCard";
import ArrobaMedellinArrobitaNoData from "../../components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import idiService from "../../services/Idi";
import ChooseUniversity from "../../components/ChooseUniversity/ChooseUniversity";
import { mapGetters } from "vuex";

export default {
  name: "ViewAllResearchGroups",
  props: {
    title: {
      type: String,
    },
    TitleNotFound: {
      type: String,
      default: "Grupos de investigación",
    },
    notFound: {
      type: String,
      default: "No tienes grupos de investigación en éste momento",
    },
  },
  components: {
    ArrobaMedellinSeeker,
    ResearchGroupsVerticalCard,
    ArrobaMedellinArrobitaNoData,
    ChooseUniversity,
  },
  data() {
    return {
      errorMessage: false,
      allResearchGroups: {},
      myResearchGroups: [],
      role: "",
      institutionId: "",
      countGroups: {},
    };
  },
  mounted() {
    this.getInfoResearchGroups();
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.role = this.$store.state.User.user.role.name;
  },
  methods: {
    async getInfoResearchGroups() {
      try {
        this.myResearchGroups = await idiService.myGroups();
        this.allResearchGroups = await idiService.researchGroupsByInstitution({
          institutionId: this.institutionId,
          take: 50,
          skip: 0,
        });
        this.countGroups = await idiService.countGroups(this.institutionId);
      } catch (error) {
        console.error(error);
      }
    },
    redirectTo(path, id) {
      this.$router.push({ name: path, params: { id: id } });
    },
    async getSearchGroups(word) {
      try {
        this.errorMessage = false;
        if (!word) {
          await this.getInfoResearchGroups();
        } else {
          const response = await idiService.searchGroupsByInstitution({
            institutionId: this.institutionId,
            word: word,
            take: 5,
            skip: 0,
          });
          if (response.length === 0) this.errorMessage = true;
          this.allResearchGroups = response;
        }
      } catch (error) {
        console.error("Error getResearchGroups", error);
      }
    },
  },
  computed: {
    roleValidation() {
      return ["Student", "Teacher"].some((a) => a === this.role);
    },
    roleValidationEntrepreneurOrTeacherInvestigator() {
      return ["Entrepreneur", "TeacherInvestigator"].some(
        (a) => a === this.role
      );
    },
    roleValidationAdministrator() {
      return ["Administrator"].some((a) => a === this.role);
    },
    roleValidationInvited() {
      return ["Invited"].some((a) => a === this.role);
    },
    ...mapGetters({
      institution: "getInstitutionSelected",
    }),
  },
  watch: {
    institution: async function (value) {
      this.institutionId = value;
      await this.getInfoResearchGroups();
    },
  },
};
</script>

<style scoped lang="scss" src="./ViewAllResearchGroups.scss"></style>∆