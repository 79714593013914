<template src='./ResearchGroupsVerticalCard.html'></template>

<script>
const { DateTime } = require("luxon");

export default {
  name: "research-groups-vertical-card",
  props: {
    name: { type: String },
    date: { type: String },
    description: { type: String },
    image: { type: String },
    imageDescription: {
      type: String,
      default: "Imagen del grupo de investigación",
    },
    buttonText: { type: String },
  },
  filters: {
    dateFormat: function (value) {
      return DateTime.fromISO(value).toFormat("dd/MM/yyyy", {
        locale: "es",
      });
    },
  },
  methods: {
    pushButton() {
      this.$emit("allResearchButton");
    },
  },
};
</script>

<style lang="scss" scoped src="./ResearchGroupsVerticalCard.scss"></style>